import { Box, BoxProps, Flex } from '@chakra-ui/react';
import React from 'react';

export interface FooterBottomProps extends BoxProps {}

export const FooterBottom = (props: FooterBottomProps) => {
  const { ...restOfProps } = props;
  return (
    <Box h={16} {...restOfProps}>
      <Flex
        h="full"
        w="full"
        alignItems="center"
        justify="center"
        fontSize={{ base: 'xs', sm: 'sm' }}
        color="grey.500"
        textAlign={'center'}
      >
        Všetky práva vyhradené pre BRICK reality s.r.o.
      </Flex>
    </Box>
  );
};
